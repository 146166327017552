type Redirect = {
  [key: string]: string
}

export const environment = {
  production: false,
  api: {
    url: 'https://plataforma-municipios.test.betha.cloud',
    // url: 'http://localhost:8081',
    token: '00de6c4e-12d4-4db7-841f-fbcf6c4f0a05'
  },
  app: {
    urlApiLogin: "",
    baseUrl: 'test.plataforma.betha.cloud',
    brasilUrl: 'https://brasil.test.plataforma.betha.cloud'
  },
  oauth: {
    url: 'https://plataforma-oauth.test.betha.cloud/auth',
    redirectUri: 'https://suite.test.plataforma.betha.cloud'
  },
  authentication: {
    scope: 'suite.services,municipios',
    clientId: '42784dcd-f062-4e06-a0ec-357193319c67'
  },
  redirects: {
    "assinador": "https://assinador.test.plataforma.betha.cloud",
  } as Redirect
};
