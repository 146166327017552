<!--<ngb-pagination [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination>-->

<h1>DANKE</h1>

<nav aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item"><a class="page-link" (click)="goToPreviousPage()">Previous</a></li>
    @for (item of items; track item) {
      <li class="page-item"><a class="page-link" (click)="setCurrentPage(item)">{{ item }}</a></li>
    }
    <li class="page-item"><a class="page-link" (click)="goToNextPage()">Next</a></li>
  </ul>
</nav>
