import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './components/main/main.component';
import { MunicipioComponent } from './components/municipio/municipio.component';
import { MapComponent } from './components/map/map.component';
import { MunicipiosListComponent } from './components/municipios-list/municipios-list.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrasaoComponent } from './components/brasao/brasao.component';
import { EstadosListComponent } from './components/estados-list/estados-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CardAcessarComponent } from "./components/card-acessar/card-acessar.component";
import {CallbackComponent} from "./callback/callback.component";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    MunicipioComponent,
    MapComponent,
    MunicipiosListComponent,
    FooterComponent,
    BrasaoComponent,
    EstadosListComponent,
    CardAcessarComponent,
    CallbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbPaginationModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
