import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  @Output() selected = new EventEmitter<string>();
  baseUrl = environment.app.baseUrl;


  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  }

  showCities(uf: string): void {
    this.document.location.href = `https://${uf}.${this.baseUrl}`;
    //this.selected.emit(uf);
  }
}
