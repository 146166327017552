import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs';
import {City, CitySearch} from '../models/City';
import { environment } from '../../environments/environment';
import { Page, PageQueryParams } from '../models/Pageable';

@Injectable({
  providedIn: 'root'
})
export class MunicipiosService {
  private municipioSelecionado?: string;

  private readonly API = `${environment.api.url}/api/v1/municipios`;

  constructor(private http: HttpClient) {}

  setMunicipioSelecionado(municipioSelecionado: string) {
    this.municipioSelecionado = municipioSelecionado;
  }

  getMunicipioSelecionado() {
    return this.municipioSelecionado;
  }

  getCityByUfAndName(name: string, uf: string) {
    const options = {
      headers: {
        "Authorization": `bearer ${environment.api.token}`
      }
    };

    return this.http.get(`${this.API}/${uf}/${name}`, options);
  }

  listCitiesByState(uf: string, params: PageQueryParams): Observable<Page<City>> {
    const queryParams = new HttpParams({
      fromObject: {
        offset: params.offset.toString(),
        limit: params.limit.toString(),
        filter: params.filter,
        sort: params.sort
      }
    });
    return this.http.get<Page<City>>(`${this.API}/${uf}/`, { params: queryParams, headers: this.getHeaders() });
  }

  findByUfAndQuerySearch(uf: string, query: string, offset: number, limit: number): Observable<Page<City>> {
    const queryParams = new HttpParams({
      fromObject: {
        offset,
        limit
      }
    })

    return this.http.get<Page<City>>(`${this.API}/search/${uf}/${query}`, {
      params: queryParams,
      headers: this.getHeaders()
    });
  }

  getBrasaoUrl(uf?: string, codigoIbge?: number): Observable<any> {
    return this.http.get(`${this.API}/${uf}/${codigoIbge}/brasao/url`, { headers: this.getHeaders() });
  }

  getBrasaoFromUrl(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: "blob" });
  }

  getDadosMunicipio(uf?: string, codigoIbge?: number) {
    return this.http.get(`${this.API}/${uf}/${codigoIbge}/dados`, { headers: this.getHeaders() });
  }

  private getHeaders() {
    return new HttpHeaders({ "Authorization": `bearer ${environment.api.token}` });
  }

  getMunicipalities(uf: string,  params: PageQueryParams) {
    const queryParams = new HttpParams({
      fromObject: {
        offset: params.offset.toString(),
        limit: params.limit.toString(),
        filter: params.filter,
        sort: params.sort
      }
    });

    return this.http.get<Page<City>>(`${this.API}/${uf}`, {
      headers: this.getHeaders(),
      params: queryParams
    });
  }
}
