import { Component, OnInit, Input } from '@angular/core';
import { City } from 'src/app/models/City';
import { environment } from "../../../environments/environment";
import { ToastService } from "../../services/toast.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  @Input() originSystem: string | undefined;

  private static URL_EXCLUDE = [
    'bethacloud', 'com', 'br', 'br:4200', 'test', 'betha', 'cloud', 'localhost:4200', 'plataforma'
  ]


  uf: string = '';
  cidade: string = '';
  siteOficial!: string;
  municipioSelecionado!: City;

  constructor(private toastService: ToastService, private routes: ActivatedRoute) { }

  ngOnInit(): void {
    this.originSystem = this.routes.snapshot.paramMap.get('originSystem') || undefined;

    this.redirectIfNeeded();
    this.getCityStateByUrl();

    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has("redirect_type") && queryParams.get("redirect_type") === "invalid_system") {
      this.toastService.warn("Você foi redirecionado devido ao sistema selecionado não existir ou não estar mapeado nos redirecionamentos");
    } else if (queryParams.has("from_redirect") && queryParams.get("from_redirect") === "true") {
      this.toastService.warn("Você foi redirecionado devido ao município selecionado não existir");
    }
  }

  redirectIfNeeded() {
    if (window.location.host === environment.app.baseUrl) {
      window.location.href = environment.app.brasilUrl;
    }

    if (this.originSystem && !environment.redirects[this.originSystem]) {
      window.location.href = this.removeAfterLastSlash(window.location.href) + "?redirect_type=invalid_system";
    }
  }

  private removeAfterLastSlash(url: string) {
    return url.substring(0, url.lastIndexOf('/') + 1);
  }

  showCity(): boolean {
    if (this.cidade != '') {
      return true;
    }
    return false;
  }

  onSelect(uf: string) {
    this.uf = uf;
  }

  setMunicipio(municipio: City) {
    this.municipioSelecionado = municipio;
    this.siteOficial = `http://${municipio.nomeUrl}.${this.uf}.gov.br`;
  }

  getCityStateByUrl(): void {

    let urlSplit = window.location.host.split('.')
      .filter(s => !MainComponent.URL_EXCLUDE.includes(s))

    if (urlSplit.length == 1) {
      this.uf = urlSplit[0]
    }

    if (urlSplit.length == 2) {
      this.cidade = urlSplit[0]
      this.uf = urlSplit[1]
    }
  }
}
