export interface State {
  id?: number;
  name: string;
  sigla: string;
}


export enum Estados {
  AC = 'Acre',
  AL = 'Alagoas',
  AP = 'Amapá',
  AM = 'Amazonas',
  BA = 'Bahia',
  CE = 'Ceará',
  DF = 'Distrito Federal',
  ES = 'Espírito Santo',
  GO = 'Goiás',
  MA = 'Maranhão',
  MT = 'Mato Grosso',
  MS = 'Mato Grosso do Sul',
  MG = 'Minas Gerais',
  PA = 'Pará',
  PB = 'Paraíba',
  PR = 'Paraná',
  PE = 'Pernambuco',
  PI = 'Piauí',
  RJ = 'Rio de Janeiro',
  RN = 'Rio Grande do Norte',
  RS = 'Rio Grande do Sul',
  RO = 'Rondônia',
  RR = 'Roraima',
  SC = 'Santa Catarina',
  SP = 'São Paulo',
  SE = 'Sergipe',
  TO = 'Tocantins'
}

const estadosBrasileiros: State[] = [
  { id: 1, name: "Acre", sigla: "AC" },
  { id: 2, name: "Alagoas", sigla: "AL" },
  { id: 3, name: "Amapá", sigla: "AP" },
  { id: 4, name: "Amazonas", sigla: "AM" },
  { id: 5, name: "Bahia", sigla: "BA" },
  { id: 6, name: "Ceará", sigla: "CE" },
  { id: 7, name: "Distrito Federal", sigla: "DF" },
  { id: 8, name: "Espírito Santo", sigla: "ES" },
  { id: 9, name: "Goiás", sigla: "GO" },
  { id: 10, name: "Maranhão", sigla: "MA" },
  { id: 11, name: "Mato Grosso", sigla: "MT" },
  { id: 12, name: "Mato Grosso do Sul", sigla: "MS" },
  { id: 13, name: "Minas Gerais", sigla: "MG" },
  { id: 14, name: "Pará", sigla: "PA" },
  { id: 15, name: "Paraíba", sigla: "PB" },
  { id: 16, name: "Paraná", sigla: "PR" },
  { id: 17, name: "Pernambuco", sigla: "PE" },
  { id: 18, name: "Piauí", sigla: "PI" },
  { id: 19, name: "Rio de Janeiro", sigla: "RJ" },
  { id: 20, name: "Rio Grande do Norte", sigla: "RN" },
  { id: 21, name: "Rio Grande do Sul", sigla: "RS" },
  { id: 22, name: "Rondônia", sigla: "RO" },
  { id: 23, name: "Roraima", sigla: "RR" },
  { id: 24, name: "Santa Catarina", sigla: "SC" },
  { id: 25, name: "São Paulo", sigla: "SP" },
  { id: 26, name: "Sergipe", sigla: "SE" },
  { id: 27, name: "Tocantins", sigla: "TO" },
  ];

const PREPOSITIVOS = {
  de: ['GO', 'MG', 'PE', 'RO', 'RR', 'SC', 'SP', 'SE'],
  do: ['AC','AL', 'AP','AM', 'CE','DF', 'ES', 'MA', 'MT', 'MS', 'PA', 'PR', 'PI', 'RJ', 'RN', 'RS', 'TO'],
  da: ['BA', 'PB'],
}

export function getPrepositivo(sigla: string) {
  if (PREPOSITIVOS.da.indexOf(sigla.toUpperCase()) !== -1) {
    return "da";
  }
  if (PREPOSITIVOS.de.indexOf(sigla.toUpperCase()) !== -1) {
    return "de";
  }
  if (PREPOSITIVOS.do.indexOf(sigla.toUpperCase()) !== -1) {
    return "do";
  }
  return "de";
}

export function getNomeByUf<Estados extends {[index:string]:string}>(myEnum:Estados, enumValue:string):keyof Estados|null {
  let keys = Object.keys(myEnum).filter(x => x == enumValue);
  return keys.length > 0 ? myEnum[keys[0]] : null;
}

export { estadosBrasileiros }
