import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { City } from 'src/app/models/City';
import { MunicipiosService } from '../../services/municipios.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-municipio',
  templateUrl: './municipio.component.html',
  styleUrls: ['./municipio.component.css']
})
export class MunicipioComponent implements OnInit {

  @Input() originSystem: string | undefined;

  @Input() municipio!: string;
  @Input() uf!: string;
  @Output() municipioSelecionado = new EventEmitter<City>();

  dadosMunicipio!: City;
  brasao!: any;
  showBrasao = true;
  informacoes!: any;
  siteOficial!: string;

  govBrEncryptedConfig: string | undefined;

  keysToShowInOrder = [

    { key: 'nome', title: "Nome" },
    { key: 'sigla', title: "Estado" },
    { key: 'Mesorregião', title: 'Mesorregião' },
    { key: 'Microrregião', title: 'Microrregião' },
    { key: 'População*', title: 'População' },
    { key: 'Área', title: "Área" },
    { key: 'Densidade Demográfica', title: 'Densidade Demográfica' }

  ]

  constructor(private service: MunicipiosService, private titleService: Title, private http: HttpClient) { }

  ngOnInit(): void {
    this.service.getCityByUfAndName(this.municipio, this.uf).subscribe({
      next: data => {
        this.dadosMunicipio = data;
        this.getDadosMunicipio(data);
        this.dadosMunicipio.nomeUrl = this.transformNameInUrlPath(this.dadosMunicipio.nome)
        this.municipioSelecionado.emit(this.dadosMunicipio);
        this.titleService.setTitle(this.dadosMunicipio.nome || 'Portal do cidadão')
      },
      error: err => {
        if (err.status == 500) {
          const queryParams = new URLSearchParams();
          queryParams.append("from_redirect", "true");

          window.location.href = `${environment.app.brasilUrl}?${queryParams.toString()}`;
        }
      }
    });

    this.siteOficial = `http://${this.municipio}.${this.uf}.gov.br`;
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.brasao = reader.result;
    }, false);

    if (image && image.size == 0) {
      this.showBrasao = false;
      return;
    }
    reader.readAsDataURL(image);
  }

  getDadosMunicipio(municipio: City) {
    this.service.getDadosMunicipio(municipio.uf, municipio.codigoIBGE).subscribe(
      data => this.informacoes = this.transformarDados(JSON.parse(data.toString()))
    )
  }

  getValueByKey(key: string) {
    return this.informacoes[key]
  }

  private transformNameInUrlPath(name: any) {
    return name.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      .toLowerCase().replaceAll(" ", "");
  }

  private transformarDados(data: any) {
    return data;
  }

  private OAUTH_AUTHORIZE_ENDPOINT = `${environment.oauth.url}/oauth2/authorize`;

  private getRedirectUri() {
    const redirects = environment.redirects;

    let redirectUri = environment.oauth.redirectUri

    if (this.originSystem) {
      redirectUri = redirects[this.originSystem] ?? environment.oauth.redirectUri;
    }

    return redirectUri;
  }

  onClick() {
    const url = `${environment.api.url}/api/v1/municipios/${this.uf}/${this.municipio}/govbr-config`;

    const queryParams = new URLSearchParams();
    queryParams.append("response_type", "token");
    queryParams.append("client_id", environment.authentication.clientId);
    queryParams.append("redirect_uri", this.getRedirectUri());
    queryParams.append("bth_ignore_origin", "true");

    this.http.get(url, {
      headers: new HttpHeaders({ "Authorization": `bearer ${environment.api.token}` })
    }).subscribe({
      next: (data: any) => {
        if (data && data.encryptedGovBrConfig) {
          queryParams.append("context_config", data.encryptedGovBrConfig);
        }

        window.location.href = `${this.OAUTH_AUTHORIZE_ENDPOINT}?${queryParams.toString()}`;
      },
      error: () => {
        window.location.href = `${this.OAUTH_AUTHORIZE_ENDPOINT}?${queryParams.toString()}`;
      }
    });
  }
}
