import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { City } from 'src/app/models/City';
import {Page, Pageable} from 'src/app/models/Pageable';
import { Estados, getNomeByUf, getPrepositivo } from 'src/app/models/State';
import { MunicipiosService } from 'src/app/services/municipios.service';
import { environment } from 'src/environments/environment';
import {fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';


@Component({
  selector: 'app-municipios-list',
  templateUrl: './municipios-list.component.html',
  styleUrls: ['./municipios-list.component.css']
})
export class MunicipiosListComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() uf!: string;

  @ViewChild('search') searchInput!: ElementRef;

  baseUrl = environment.app.baseUrl;
  municipios!: Page<City>;
  nomeEstado!: any;

  getPrepositivo = getPrepositivo
  loading = true;
  placeholder = ` Buscar o ${(!this.nomeEstado ? 'estado' : 'município')} desejado`
  sort = 'asc';

  listOfPages: number[] = [];

  pageable = {
    offset: 0,
    limit: 80,
    paginationLimit: 8
  };

  hasNext = true;
  offset = 0;
  limit = 8;

  term = "";

  constructor(private service: MunicipiosService, private routes: ActivatedRoute, private titleService: Title) {
    this.uf = this.routes.snapshot.paramMap.get('uf') || '';
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement,'keyup')
    .pipe(
        debounceTime(500),
        tap(() =>
          this.findMunicipioByName(this.searchInput.nativeElement.value)
        )
    )
    .subscribe();
  }

  ngOnChanges() {
   this.loadData();
  }

  // @ts-ignore
  listMunicipios() {
    if (this.term != "") {
      this.service.findByUfAndQuerySearch(this.uf, this.term, this.offset, this.limit)
        .subscribe(mun => {
          if (!mun.content || mun.content.length == 0) {
            this.loading = false;
            return;
          }

          mun.content = mun.content.map(m => {
            return {...m, nomeUrl: this.transformNameInUrlPath(m.nome)}
          });

          this.municipios = mun;
          this.hasNext = mun.hasNext!;

          this.loading = false;
        });
    } else {
      this.service.listCitiesByState(this.uf, { limit: this.limit, offset: this.offset, filter: "", sort: "nome asc" })
        .subscribe(mun => {
          if (!mun.content || mun.content.length == 0) {
            this.loading = false;
            return;
          }

          mun.content = mun.content.map(m => {
            return {...m, nomeUrl: this.transformNameInUrlPath(m.nome)}
          });

          this.municipios = mun;
          this.hasNext = mun.hasNext!;

          this.loading = false;
        });
    }
  }

  async findMunicipioByName(searchTerm: string) {
    this.offset = 0;
    this.term = searchTerm;
    this.listMunicipios();
  }

  toggleOrder() {
    this.sort = this.sort === "asc" ? "desc" : "asc";
    this.loadData();
  }

  private loadData() {
    this.listMunicipios();
    this.nomeEstado = getNomeByUf(Estados, this.uf.toUpperCase())
    this.titleService.setTitle(`Municípios de ${this.nomeEstado}`);
  }

  private transformNameInUrlPath(name: any) {
    return name.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      .toLowerCase().replaceAll(" ", "");
  }

  currentPage = 1;

  goToNextPage() {
    if (this.hasNext) {
      this.offset += this.limit;
      this.listMunicipios();
    }
  }

  goToPreviousPage() {
    if (this.offset >= this.limit) {
      this.offset -= this.limit;
      this.listMunicipios();
    }
  }
}
