import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EstadosService } from 'src/app/services/estados.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-estados-list',
  templateUrl: './estados-list.component.html',
  styleUrls: ['./estados-list.component.css']
})
export class EstadosListComponent implements OnInit {

  @Input() uf!: string;

  @Output() selected = new EventEmitter<string>();

  showCities(uf: string): void {
    this.selected.emit(uf);
  }

  baseUrl = environment.app.baseUrl;
  nomeEstado!: any;

  loading = true;
  placeholder = ` Buscar o estado desejado`
  sort = 'asc';
  estados: any = [];
  limit = 8;
  current = 1;
  offset = 0;
  offsetEnd = 8;

  constructor(private routes: ActivatedRoute, private service: EstadosService) {
    this.uf = this.routes.snapshot.paramMap.get('uf') || '';
  }

  ngOnInit(): void {
    this.listEstados();
    this.setOffsets(1);
  }

  ngOnChanges() {
   this.listEstados();
  }

  onPageChange(page: number) {
    // this.setOffsets(eventPage);
    // if(isNaN(eventPage)) {
    //   return;
    // }
    this.listEstados(this.limit, page)
  }

  onPageSizeChange() {
    this.listEstados(this.limit)
  }

  findEstadoByName(filter: string, order: string = this.sort) {
    this.loading = true;
    this.estados = this.service.listEstados(order, filter, this.limit);
    this.loading = false;

  }

  private listEstados(limit: number = this.limit, current: number = this.currentPage) {
    this.loading = true;
    this.estados = this.service.listEstados(this.sort, '', limit, current);
    this.loading = false;
  }

  toggleOrder() {
    this.sort = this.sort === "asc" ? "desc" : "asc";
    this.listEstados(this.limit, this.current);
  }


  private setOffsets(current: number) {
    this.offset = 1 + this.limit * (current -1);
    this.offsetEnd = this.offset + this.limit -1;
    if (this.offsetEnd > 27) {
      this.offsetEnd = 27;
    }
  }

  getNumberOfPages() {
    let numberOfPages = Math.floor(this.estados.total / this.limit);
    numberOfPages = this.estados.total % this.limit > 0 ? numberOfPages + 1 : numberOfPages;
    return numberOfPages;
  }

  getListOfPages() {
    const numberOfPages = this.getNumberOfPages();

    let listOfPages = [];

    for (let i = 1; i < numberOfPages + 1; i++) {
      listOfPages.push(i);
    }

    return listOfPages;
  }

  currentPage = 1;

  setCurrentPage(page: number) {
    this.currentPage = page;
    this.onPageChange(page);
  }

  goToNextPage() {
    if (this.currentPage + 1 < this.getNumberOfPages()) {
      this.setCurrentPage(this.currentPage + 1);
    }
  }

  goToPreviousPage() {
    if (this.currentPage -1 > 0) {
      this.setCurrentPage(this.currentPage - 1);
    }
  }
}
