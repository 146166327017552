import { Injectable } from '@angular/core';
import { State } from '@popperjs/core';
import { FakePage } from '../models/Pageable';
import { estadosBrasileiros } from '../models/State';

@Injectable({
  providedIn: 'root'
})
export class EstadosService {
  private estadoSelecionado?: string;
  //private estados = Array.from(estadosBrasileiros);

  private estados = {
    content: Array.from(estadosBrasileiros),
    current: 1,
    limit: 8,
    offset: 0,
    total: estadosBrasileiros.length
  };


  constructor() { }

  setEstadoSelecionado(estadoSelecionado: string) {
    this.estadoSelecionado = estadoSelecionado;
  }

  getEstadoSelecionado() {
    return this.estadoSelecionado;
  }

  listEstados(sort: string = 'asc', filter: string = '', limit: number = 8, current: number = 1) {
    this.estados.current = current;
    this.estados.limit = limit;

    this.applyFilter(filter);
    this.estados.total = this.estados.content.length;
    console.table(this.estados)
    this.applySort(sort);
    this.applyLimit(limit, current);
    return this.estados;
  }

  private applyFilter(filter: string) {
    this.estados.content =  Array.from(estadosBrasileiros);
    if (filter !== '') {
      let regex = new RegExp( filter, 'gi' );
      this.estados.content = estadosBrasileiros.filter(
        estado => regex.test(estado.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
      )
    }
  }

  private applySort(sort: string) {
    this.estados.content = this.estados.content.sort( (a,b) => sort === 'asc' ?  this.sortAsc(a,b) : this.sortDesc(a,b));
  }

  private applyLimit(limit: number, current: number) {
    const slice = (current - 1) * limit;
    this.estados.content = this.estados.content.slice(slice, slice + limit);
  }

  private sortAsc(a: any,b: any) {
    return a.name < b.name ? -1 : 1;
  }

  private sortDesc(a: any,b: any) {
    return a.name < b.name ? 1 : -1;
  }

}
