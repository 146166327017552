<div class="container">
  <div class="card">
    <div class="card-body">

      <!--SEARCH CARD-->
      <div class="d-flex flex-wrap">
        <div class="col-md-6">
          <h3 *ngIf="!nomeEstado">Selecione um estado</h3>
          <h3 *ngIf="nomeEstado">Municipios {{ getPrepositivo(uf)}} {{ uf.toUpperCase() }}</h3>
        </div>
        <div class="input-container col-md-5">
<!--          <input #search class="input-field w-100" type="text" onfocus="this.placeholder=''" (blur)="search.placeholder=placeholder" [placeholder]="placeholder" (keyup.enter)="findEstadoByName(search.value)">-->
              <input #search class="input-field w-100" type="text" onfocus="this.placeholder=''" (blur)="search.placeholder=placeholder" [placeholder]="placeholder">
          <div class="input-group-append">
            <span class="icon p-3 mdi mdi-magnify"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- ORDER -->
    <div class="card-header">
      <a (click)="toggleOrder()">
        <span class="text-left order">Ordem alfabetica  </span>
        <span class="mdi order" [class]="sort === 'asc' ? 'mdi-arrow-up' : 'mdi-arrow-down'"></span>
      </a>
    </div>

    <!-- SPINNER-->
    <div class="text-center m-5" *ngIf="loading">
      <div class="spinner-border" role="status">
            <span class="sr-only"></span>
      </div>
    </div>

    <div *ngIf="!loading">
      <!--LIST-->
      <ul class="list-group list-group-flush">
        @for (municipio of municipios.content; track municipio) {
          <li class="list-group-item card-effect">
            <a class="d-flex flex-wrap" href="https://{{ municipio.nomeUrl }}.{{ uf }}.{{ baseUrl }}">
              <!-- ESCUDO -->
              <div style="height: 50px; width: 50px;">
                <app-brasao [municipio]="municipio" [class]="'blazon-circle'"></app-brasao>
              </div>
              <div>
                <span class="title">{{ municipio.nome }}</span>
                <br>
                <span class="subtitle">{{ uf }}</span>
              </div>
            </a>
          </li>
        }
      </ul>
      <ul *ngIf="municipios.content.length == 0">
        <h5 class="card-title m-4 text-center">Nenhum munícipio encontrado</h5>
      </ul>
    </div>
    </div>

    <!--PAGINATION-->
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="page-item" [class.disabled]="offset < limit">
        <button class="page-link" (click)="goToPreviousPage()" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>
      <!-- Dynamically generate page numbers here if you have that info -->
      <li class="page-item" [class.disabled]="!hasNext">
        <button class="page-link" (click)="goToNextPage()" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>
  </nav>
</div>
