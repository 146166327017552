import { AfterViewInit, Component, Inject, Input, OnInit, } from '@angular/core';
import { City } from 'src/app/models/City';
import { MunicipiosService } from 'src/app/services/municipios.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-brasao',
  templateUrl: './brasao.component.html',
  styleUrls: ['./brasao.component.css'],
})
export class BrasaoComponent implements AfterViewInit, OnInit {

  @Input() municipio!: City;
  @Input() class!: string;

  brasao!: any;
  showBrasao = false;


  constructor(private service: MunicipiosService, @Inject(DOCUMENT) document: Document) { }

  ngOnInit(): void {
    this.getUrlBrasao(this.municipio);
  }

  ngAfterViewInit() {
    this.geraBrasaoPadrao();
  }

  private getUrlBrasao(municipio: City) {
    this.service.getBrasaoUrl(municipio.uf, municipio.codigoIBGE)
      .subscribe(({ url }) => {
        this.service.getBrasaoFromUrl(url).subscribe(data => {
          this.createImageFromBlob(data)
        })
      });
  }

  private createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.brasao = reader.result;
    }, false);

    if (image && image.size == 0) {
      this.showBrasao = false;
      return;
    }
    this.showBrasao = true;
    reader.readAsDataURL(image);
  }

  private geraBrasaoPadrao() {
    const initials = this.getInitials();
    let canvas = <HTMLCanvasElement>document.getElementById("brasaoUndefined-" + this.municipio.nomeUrl);
    let canvasContext = canvas.getContext("2d") || new CanvasRenderingContext2D();

    // draw background
    canvasContext.fillStyle = this.getRandomColour();
    canvasContext.beginPath();
    canvasContext.rect(70, 50, 100, 100);
    canvasContext.closePath();

    // draw initials
    canvasContext.fillRect(0, 0, 200, 100);
    canvasContext.font = "24px Arial";
    canvasContext.fillStyle = "#FFFF";
    canvasContext.fillText(initials, (canvas.width / 2) - 14, 34)
    canvasContext.closePath();
  }

  private getInitials() {
    let name = this.municipio.nome || "";
    let worlds = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").split(' ')

    if (worlds.length > 1) {
      return worlds[0][0] + worlds[1][0];
    } else {
      return worlds[0][0] + worlds[0][1];
    }
  }

  private getRandomColour(): string {
    const red = Math.floor(Math.random() * 255);
    const green = Math.floor(Math.random() * 255);
    const blue = Math.floor(Math.random() * 255);
    return `rgb(${red},${green},${blue})`;
  }
}
