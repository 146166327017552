import { Component, OnInit, } from '@angular/core';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnInit {
  ngOnInit(): void {

  }
}
