<div class="row" *ngIf="!showCity()">
  <div class="col-sm d-none d-lg-block p-3">
    <app-map (selected)="onSelect($event)" *ngIf="cidade == ''"></app-map>
  </div>
  <div class="col-sm">
    <app-municipios-list class="d-flex p-4" *ngIf="uf && uf !='brasil' && cidade ==''" [uf]="uf"></app-municipios-list>
    <app-estados-list (selected)="onSelect($event)" class="d-flex p-4" *ngIf="uf && uf =='brasil' || !uf"
      [uf]="uf"></app-estados-list>
  </div>
</div>

<app-municipio (municipioSelecionado)="setMunicipio($event)" *ngIf="showCity()" [municipio]="cidade" [uf]="uf"
  [originSystem]="originSystem"></app-municipio>
