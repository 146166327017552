import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MunicipioComponent } from './components/municipio/municipio.component';
import { MunicipiosListComponent } from './components/municipios-list/municipios-list.component';
import { MainComponent } from "./components/main/main.component";
import { CallbackComponent } from "./callback/callback.component";
import { CardAcessarComponent } from "./components/card-acessar/card-acessar.component";

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: ':originSystem', component: MainComponent },
  { path: 'pagination', component: CardAcessarComponent },
  { path: 'uf/:uf', component: MunicipiosListComponent },
  { path: 'uf/:uf/municipio/:ibge', component: MunicipioComponent },
  { path: 'callback', component: CallbackComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
