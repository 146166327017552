
<div class="container-fluid">
  <div class="row vh-100 align-items-center">

    <div class="col justify-content-end d-none d-lg-block">
      <img class="d-block ml-auto" style="margin: 0 auto;" src="/assets/images/mapa-brasil.svg" alt="Mapa do Brasil estilizado" style="width: 100%; max-width: 600px;">
    </div>

    <div class="col justify-content-center">

      <div class="municipio__card px-5">

        <div class="betha__logo">
          <img src="/assets/images/betha-logo-azul.svg" alt="Logo betha azul">
          <img src="/assets/images/cloud-logo.svg" alt="Cloud logo">
        </div>

        <div class="municipio__brasao">
          <app-brasao *ngIf="dadosMunicipio" [municipio]="dadosMunicipio"></app-brasao>
          {{ dadosMunicipio.nome }}
          <small>
            / {{ dadosMunicipio.uf }}
          </small>
        </div>

        <p class="municipio__description">Mais flexibilidade e agilidade para acessar os sistemas do seu município</p>

        <button class="btn btn-primary btn-block municipio__btn" (click)="onClick()">Acessar</button>

      </div>
    </div>

  </div>
</div>
