import { Injectable } from '@angular/core';

export interface ToastAction {
  title: string;
  onClick: () => void;
  close?: boolean;
}

export interface Options {
  type: 'danger' | 'warn' | 'success' | 'info';
  description: string;
  title?: string;
  timeout?: number;
  hideClose?: boolean;
  htmlSafe?: boolean;
  onClose?: () => void;
  actions?: ToastAction[];
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  publish(options: Options): void {
    // @ts-ignore
    const toast: HTMLBthToastElement = document.querySelector('bth-toast');
    toast.publish(options as any);
  }

  warn(description: string): void {
    this.publish({ type: 'warn',  description });
  }

  success(description: string): void {
    this.publish({ type: 'success',  description });
  }

  info(description: string): void {
    this.publish({ type: 'info',  description });
  }
}
