<div class="container">
  <div class="card">
    <div class="card-body">

      <!--SEARCH CARD-->
      <div class="d-flex flex-wrap">
        <div class="col-md-6">
          <h3 *ngIf="!nomeEstado">Selecione um estado</h3>
        </div>
        <div class="input-container col-md-5">
          <input #search class="input-field w-100" type="text" onfocus="this.placeholder=''" (keyup.enter)="findEstadoByName(search.value)" (blur)="search.placeholder=placeholder" [placeholder]="placeholder">
          <div class="input-group-append">
            <span class="icon p-3 mdi mdi-magnify"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- ORDER -->
    <div class="card-header">
      <a (click)="toggleOrder()">
        <span class="text-left order">Ordem alfabetica  </span>
        <span class="mdi order" [class]="sort === 'asc' ? 'mdi-arrow-up' : 'mdi-arrow-down'"></span>
      </a>
    </div>

    <!-- SPINNER-->
    <div class="text-center m-5" *ngIf="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div *ngIf="!loading">
      <!--LIST-->
      <ul class="list-group list-group-flush">
        <li class="list-group-item card-effect" *ngFor="let estado of estados.content; let i=index">
          <a class="d-flex flex-wrap"  href="https://{{estado.sigla}}.{{baseUrl}}">
            <!-- ESCUDO -->
            <div>
              <img class="blazon-circle" src="/assets/images/brasoes/{{estado.sigla}}.png" alt="Brasão {{ estado.name}} ">
            </div>
            <div>
              <span class="title">{{ estado.name }}</span>
              <br>
              <span class="subtitle">{{ estado.sigla }}</span>
            </div>
          </a>
        </li>
      </ul>
      <ul *ngIf="estados.length === 0">
        <h5 class="card-title m-4 text-center">Nenhum estado encontrado</h5>
      </ul>
    </div>
    </div>
        <!--PAGINATION-->
        <div class="mt-2" *ngIf="!loading && estados.total > 0">
          <div class="float-left d-none d-md-inline">
            <div class="d-flex flex-row align-items-center">
              <div class="mr-2">
                <span>{{ offset }}-{{ offsetEnd }}
                  de
                  {{ estados.total }}</span>
              </div>
              <div class="mr-2">
                <select class="form-control" [(ngModel)]="limit" (change)="onPageSizeChange()">
                  <option value="8">8</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </select>
              </div>
              <div class="mr-2">
                <span> por página</span>
              </div>
            </div>
          </div>

          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" (click)="goToPreviousPage()">Previous</a></li>
              @for (item of getListOfPages(); track item) {
                <li class="page-item"><a class="page-link" (click)="setCurrentPage(item)">{{ item }}</a></li>
              }
              <li class="page-item"><a class="page-link" (click)="goToNextPage()">Next</a></li>
            </ul>
          </nav>

<!--          <ngb-pagination (pageChange)="onPageChange($event)"-->
<!--            [collectionSize]="estados.total"-->
<!--            [(page)]="current"-->
<!--            [pageSize]="estados.limit"-->
<!--            [maxSize]="4"-->
<!--            class="float-right">-->
<!--            <ng-template ngbPaginationPrevious>-->
<!--              <div class="d-flex">-->
<!--                <i class="mdi mdi-chevron-left mr-1"></i>-->
<!--                <span class="d-none d-lg-block">Anterior</span>-->
<!--              </div>-->
<!--            </ng-template>-->
<!--            <ng-template ngbPaginationNext>-->
<!--              <div class="d-flex">-->
<!--                <span class="d-none d-lg-block">Próxima</span>-->
<!--                <i class="mdi mdi-chevron-right ml-1"></i>-->
<!--              </div>-->
<!--            </ng-template>-->
<!--            <ng-template ngbPaginationNumber let-pageNumber>{{ pageNumber }}</ng-template>-->
<!--          </ngb-pagination>-->
        </div>
</div>
