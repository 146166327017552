import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-acessar',
  templateUrl: './card-acessar.component.html',
  styleUrls: ['./card-acessar.component.css'],
})
export class CardAcessarComponent implements OnInit {
  currentPage = 1;
  items = [1, 2, 3, 4, 5]

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  goToNextPage() {
    this.setCurrentPage(this.currentPage + 1);
  }

  goToPreviousPage() {
    this.setCurrentPage(this.currentPage -1);
  }

  ngOnInit(): void {
  }

}
