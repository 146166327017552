
<canvas id="brasaoUndefined-{{municipio.nomeUrl}}" *ngIf="!showBrasao"
  [class]="class"
  width="40"
  height="48">
</canvas>
<img *ngIf="showBrasao == true"
  [class]="class"
  [src]="brasao"
  style="max-width: 40px; max-height: 40px"
  alt="Brasão do município de {{ municipio.nome}}">
